import "./src/styles/tailwind.base.css";
import "./src/styles/tailwind.components.css";
import "./src/styles/index.css";
import "./src/styles/tailwind.utilities.css";

export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps
}) => {
  if (location.state && location.state.shouldScroll === false) {
    return false;
  }

  if (location.hash && prevRouterProps) {
    return prevRouterProps.location.pathname === location.pathname;
  }

  return true;
};
